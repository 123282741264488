import { Path } from "@react-pdf/renderer";

import { Svg } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

type PDFSVGProps = {
  style: Style | Style[] | undefined;
  strokeColor: string;
};

export const EmailPDFSVG = ({ style, strokeColor }: PDFSVGProps) => {
  return (
    <Svg
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      stroke="inherit"
      style={style}
    >
      <Path
        fill="none"
        stroke={strokeColor}
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
      ></Path>
    </Svg>
  );
};

export const PhonePDFSVG = ({ style, strokeColor }: PDFSVGProps) => {
  return (
    <Svg
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      stroke="inherit"
      style={style}
    >
      <Path
        fill="none"
        stroke={strokeColor}
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
      />
    </Svg>
  );
};

export const LocationPinPDFSVG = ({ style, strokeColor }: PDFSVGProps) => {
  return (
    <Svg
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      stroke="inherit"
      style={style}
    >
      <Path
        fill="none"
        stroke="#4b5563"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
      <Path
        fill="none"
        stroke="#4b5563"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
      />
    </Svg>
  );
};

export const CalenderPDFSVG = ({ style, strokeColor }: PDFSVGProps) => {
  return (
    <Svg
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      stroke="inherit"
      style={style}
    >
      <Path
        fill="none"
        stroke="#4b5563"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
      />
    </Svg>
  );
};

export const BriefcasePDFSVG = ({ style, strokeColor }: PDFSVGProps) => {
  return (
    <Svg
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      stroke="inherit"
      style={style}
    >
      <Path
        fill="none"
        stroke="#4b5563"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
      />
    </Svg>
  );
};
